<template>
  <div class="main_box">


    <el-form class="main-form" ref="form" :model="condition" :inline="true" label-width="50px" size="medium">

      <el-form-item label="门店:">
        <el-select v-model="condition.shopId" clearable placeholder="请选择门店">
          <el-option v-for="item in storeList" :key="item.value" :value="item.value" :label="item.label" />
        </el-select>
      </el-form-item>

      <el-form-item label="状态:">
        <el-select v-model="condition.state" clearable placeholder="请选择状态">
          <el-option v-for="item in deviceStateList" :key="item.value" :value="item.value" :label="item.label" />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="fResearch" size="medium">搜 索</el-button>
        <el-button type="danger" @click="reset" size="medium">重 置</el-button>
      </el-form-item>

    </el-form>

    <div v-if="tableData.length" class="table_box">
      <div v-for="item in tableData" :key="item.equipId" class="machine-card">
        <div class="card-edit">
          <GIcon :class="stateClass[item.state]" :icon="stateIcon[item.state]" />
        </div>
        <div class="card-head">
          <GIcon v-if="item.equipType === 'pc'" class="icon" :icon="item.osType.indexOf('Win') === 0 ? 'icon-windows1' : 'icon-mac-os'" />
          <GIcon v-else class="icon" icon="icon-ipad1" />
          <div class="card-head-name">
            <p>{{ item.equipName }}</p>
            <p class="card-head-des">{{ item.macAddress }}</p>
            <p class="card-head-des">安装时间: {{ item.createTime }}</p>
          </div>
        </div>
        <div class="global-flex">
          <span class="global-flex-label">门店: </span>
          <span class="global-flex-value">{{ item.shopName }}</span>
        </div>
        <div class="global-flex">
          <span class="global-flex-label">IP: </span>
          <span class="global-flex-value global-flex-remak">{{ item.ip }}</span>
        </div>
        <div class="global-flex">
          <span class="global-flex-label">地址: </span>
          <span class="global-flex-value global-flex-remak">{{ item.ipAddress }}</span>
        </div>
        <div class="global-flex">
          <span class="global-flex-label">版本: </span>
          <span class="global-flex-value">{{ item.clientVersion }}</span>
        </div>
        <div class="global-flex">
          <span class="global-flex-label">数据: </span>
          <span class="global-flex-value">{{ item.dataVersion }}</span>
        </div>
        <div class="global-flex">
          <span class="global-flex-label">活跃: </span>
          <span class="global-flex-value">{{ item.activeTime }}</span>
          <Tag v-if="item.activeTimeStr" style="margin-left: 12px" size="small">{{ item.activeTimeStr }}</Tag>
        </div>
        <div class="card-footer">
          <GIcon v-rights="'athena:device:delete'" class="card-footer-del" icon="icon-shanchu" @click.native="deleteDevice(item)" title="删除设备" />
          <el-button v-rights="'athena:device:edit'" v-if="item.state !== '10'" type="primary" size="small" @click="editDeviceState(item, '10')">授权</el-button>
          <el-button v-rights="'athena:device:edit'" v-if="item.state === '10'" type="warning" size="small" @click="editDeviceState(item, '40')">锁定</el-button>
        </div>
      </div>
    </div>
    <div v-else class="norma-data">
      <Empty :image="simpleImage" />
    </div>
  </div>
</template>

<script>
  import { Tooltip, Tag } from 'element-ui'
  import { Empty } from 'ant-design-vue'

  export default {
    name: 'Customer',
    components: { 'el-tooltip': Tooltip, Tag, Empty },
    data () {
      return {
        simpleImage: '',
        osTypeIcon: {
          iOS: 'icon-ios',
          ipad: 'icon-ipad'
        },
        stateClass: {
          10: 'icon-auth',
          20: 'icon-no-auth',
          30: 'icon-wait',
          40: 'icon-lock',
        },
        stateIcon: {
          10: 'icon-yishouquan1',
          20: 'icon-weishouquan',
          30: 'icon-daishouquan',
          40: 'icon-yisuoding',
        },
        condition: {},
        tableData: [],
        storeList: [],
        deviceStateList: [
          { value: '10', label: '已授权' },
          { value: '20', label: '未授权' },
          { value: '30', label: '待授权' },
          { value: '40', label: '已锁定' }
        ],
        deviceTypeList: [
          { value: 'pc', label: 'PC' },
          { value: 'ipad', label: 'iPad' }
        ],
      }
    },
    computed: {
      rescData () {
        return this.$store.getters.userRights
      }
    },
    created () {
      this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
      this.getTableData()
      this.getStoreList()
    },
    methods: {
      async getTableData () {
        const response = await this.$axios.get('/athena/equip/list', { ...this.condition })
        this.tableData = response.data
      },
      fResearch () {
        this.getTableData()
      },
      reset() {
        this.condition.shopId = ''
        this.condition.state = ''
        this.getTableData()
      },
      fReset () {
        this.condition = {}
        this.fResearch()
      },
      async editDeviceState ({ equipId }, state) {
        const statusText = state === '10' ? '授权' : '锁定'
        const self = this
        this.$antConfirm({
          title: `${statusText}`,
          content: `是否保存${statusText}此设备`,
          width: '500px',
          okText: statusText,
          okType: state === '10' ? 'primary' : 'warning',
          cancelText: '取消',
          onOk() {
            self.$axios.post('/athena/equip/editState', { state, equipId }).then(res => {
              if (res.code === '0000') {
                self.$message.success('修改成功')
                self.getTableData()
              }
            })
          },
          onCancel() {}
        })
      },
      deleteDevice ({ equipId }) {
        const self = this
        this.$antConfirm({
          title: '删除设备',
          content: '删除后，设备及其数据均被删除，无法恢复。\n 是否确定删除此设备？',
          width: '500px',
          okText: '删除',
          okType: 'danger',
          cancelText: '取消',
          onOk() {
            self.$axios.post('/athena/equip/delete', { equipId }).then(response => {
              if (response.code === '0000') {
                self.$message.success('删除成功')
                self.getTableData()
              }
            })
          },
          onCancel() {}
        })
      },
      async getStoreList () {
        const response = await this.$axios.get('/hera/shop/list')
        this.storeList = response.data.map(item => {
          return { value: item.id, label: item.name }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .main_box {
    height: 100%;
    padding: 13px;
    .search_box {
      .search_item {
        width: 180px;
      }
    }
    .table_box {
      padding: 24px 24px 0;
      height: calc(100% - 80px);
      display: flex;
      overflow-y: auto;
      flex-wrap: wrap;
      .machine-card{
        position: relative;
        width: 320px;
        height: max-content;
        background: #fff;
        box-shadow: $cardShadow;
        border-radius: 4px;
        margin: 0 24px 24px 0;
        padding: 12px;
        cursor: pointer;
        transition: all .3s;
        z-index: 11;
        &:hover{
          transform: scale(1.1);
        }
        .card-head{
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px;
          display: flex;
          align-items: center;
          border-bottom: 1px $themeBg solid;
          padding-bottom: 10px;
          font-weight: bold;
          color: $title1;
          .icon{
            font-size: 60px;
            color: #409EFF;
            margin: 0 8px 0 2px;
          }
          .card-head-name{
            width: calc(100% - 86px);
            line-height: 20px;
            p{
              width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            .card-head-des{
              font-size: 12px;
              color: $title2;
              font-weight: normal;
            }
          }
          span{
            display: inline-block;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        .global-flex{
          align-items:  baseline;
          line-height: 40px;
          color: $title1;
          .global-flex-label{
            margin-right: 10px;
            min-width: 50px;
            text-align: right;
            font-weight: 500;
          }
          .global-flex-value{
          }
          .global-flex-remak{
            line-height: 24px;
          }
        }
        .card-footer{
          margin-top: 12px;
          border-top: 1px $themeBg solid;
          padding-top: 12px;
          text-align: right;
          position: relative;
          .card-footer-del{
            position: absolute;
            left: 0;
            top: 12px;
            font-size: 30px;
            color: #F56C6C;
          }
        }
      }
    }
    .card-edit{
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 10;
      .icon{
        font-size: 60px;
      }
      .icon-auth{
        color: #67C23A;
      }
      .icon-no-auth{
        color: #a9adb0;
      }
      .icon-lock{
        color: #F56C6C;
      }
      .icon-wait{
        color: #909399;
      }
    }
    .main-form{
      box-shadow: $cardShadow;
      background: #fff;
      border-radius: 4px;
      padding: 12px 12px;
      ::v-deep{
        .el-form-item__label{
          padding: 0;
          text-align: left;
        }
        .el-form-item{
          margin-bottom: 0;
        }
      }
    }
  }
  .el-select-dropdown__item{
    padding: 0 12px;
  }
  .norma-data{
    width: 100%;
    height: calc(100% - 72px);
    margin-top: 12px;
    background: #fff;
    box-shadow: var(--cardShadow);
    border-radius: 4px;
    padding-top: 32px;
  }
  ::v-deep{
  }
</style>
